/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  TextField,
  Box,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import Loading from '../../components/Loading';
import { searchClassroomsBuildInIntegra } from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { IClassroom, IClassroomPagination } from '../../../core/interfaces';
import CardClassroomInBuild from '../../components/CardClassroomInBuild';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface FilterComponentProps {
  onFilterChange: (filter: string, filterType: string) => void;
  onSearchClick: (filter: string, filterType: string) => void;
  onClearClick: () => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onFilterChange,
  onSearchClick,
  onClearClick,
}) => {
  const [filter, setFilter] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('code'); // default filter type

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilter = event.target.value;
    setFilter(newFilter);
    onFilterChange(newFilter, filterType);
  };

  const handleFilterTypeChange = (event: SelectChangeEvent<string>) => {
    const newFilterType = event.target.value as string;
    setFilterType(newFilterType);
    onFilterChange(filter, newFilterType);
  };

  const handleSearchClick = () => {
    onSearchClick(filter, filterType);
  };

  const handleClearFilterClick = () => {
    setFilter('');
    onClearClick();
  };

  return (
    <Box
      mb={2}
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <FormControl variant="outlined" style={{ minWidth: 150 }}>
        <InputLabel id="filter-type-label">Filtrar por</InputLabel>
        <Select
          labelId="filter-type-label"
          value={filterType}
          onChange={handleFilterTypeChange}
          label="Filtrar por"
        >
          <MenuItem value="code">Código</MenuItem>
          <MenuItem value="name">Nome</MenuItem>
          <MenuItem value="code_sas">Código do SAS</MenuItem>
        </Select>
      </FormControl>
      <Box display="flex" alignItems="center" flex="1">
        <TextField
          label="Pesquisar"
          variant="outlined"
          value={filter}
          onChange={handleInputChange}
          fullWidth
        />
        <IconButton onClick={handleSearchClick} aria-label="search">
          <SearchIcon />
        </IconButton>
        <IconButton onClick={handleClearFilterClick} aria-label="clear filter">
          <ClearIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const ListClassroomsIntegraInBuild: React.FC = () => {
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const [data, setData] = useState<IClassroomPagination>(
    {} as IClassroomPagination,
  );
  const [filteredClassrooms, setFilteredClassrooms] = useState<IClassroom[]>(
    [],
  );
  const [filter, setFilter] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('code'); // default filter type
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  async function fetchDataAPI(
    next?: string,
    previous?: string,
    params?: URLSearchParams,
  ) {
    setOpenLoading(true);
    const classroomData = await searchClassroomsBuildInIntegra(
      next,
      previous,
      params,
    );
    setOpenLoading(false);

    if (classroomData instanceof ErrorAPI) {
      if (classroomData.statusCode === 500) {
        navigate('/logout');
      }
    } else {
      setTotalPages(Math.ceil(classroomData.count / itemsPerPage));
      setData(classroomData);
      setClassrooms(classroomData.results);
      setFilteredClassrooms(classroomData.results);
      return classroomData.results;
    }
  }

  useEffect(() => {
    fetchDataAPI();
  }, [navigate]);

  const handleFilterChange = (newFilter: string, newFilterType: string) => {
    setFilter(newFilter);
    setFilterType(newFilterType);
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    if (page < value) {
      fetchDataAPI(data.next);
    } else {
      fetchDataAPI(data.previous);
    }
    setPage(value);
  };

  const handleButtonFilter = () => {
    const params = new URLSearchParams();
    if (filter === '') {
      return;
    }
    if (filterType === 'code') {
      params.set('code_classroom', filter);
      fetchDataAPI(undefined, undefined, params);
      params.delete('code_classroom');
    }
    if (filterType === 'name') {
      params.set('solution_name', filter);
      fetchDataAPI(undefined, undefined, params);
      params.delete('solution_name');
    }

    if (filterType === 'code_sas') {
      params.set('event_id', filter);
      fetchDataAPI(undefined, undefined, params);
      params.delete('event_id');
    }
  };

  return (
    <>
      <Box m={2} display="flex" flexDirection="column" alignItems="center">
        <FilterComponent
          onFilterChange={handleFilterChange}
          onSearchClick={handleButtonFilter}
          onClearClick={() => {
            fetchDataAPI();
          }}
        />
      </Box>

      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="center"
      >
        {filteredClassrooms.map((classroom) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={classroom.code_classroom}
          >
            <CardClassroomInBuild classroom={classroom} />
          </Grid>
        ))}
      </Grid>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
      />
      <Loading open={openLoading} />
    </>
  );
};

export default ListClassroomsIntegraInBuild;
