/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import TextMaskCustomCPF from '../../components/TextMaskCustomCPF';
import DBGrid from '../../components/Grid';
import style from './style.module.css';
import { IStudentCertificate } from '../../../core/interfaces';
import { useState } from 'react';
import { getCertificatesFromCPFAPI } from '../../../service/StudentService';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import ErrorAPI from '../../../core/models/ErrorAPI';
import SnackBar from '../../components/SnackBar';
import base64toFile from '../../../core/utils/base64toFile';

const ListCertificate: React.FC = () => {
  const [certificates, setCertificates] = useState<object[]>([]);
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const navigate = useNavigate();
  const columns = [
    { field: 'id', headerName: 'ID do evento', width: 50 },
    { field: 'classname', headerName: 'Nome da turma', width: 400 },
    { field: 'solution_name', headerName: 'Nome da solução', width: 300 },
    {
      field: 'supplier',
      headerName: 'Professores',
      width: 350,
      type: 'string',
    },
    { field: 'pdf_url', headerName: 'Link certificado', width: 130 },
    { field: 'pdf_base64', headerName: 'Link certificado', width: 130 },
    {
      field: 'actions',
      headerName: 'Opções',
      width: 160,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const handleActionTable = async () => {
          console.log(params);
          base64toFile(params.row.pdf_base64, params.row.classname, true);
        };
        return (
          <>
            <Button
              id={style.bttnActionTable}
              onClick={handleActionTable}
              variant="contained"
              size="small"
            >
              Baixar certificado
            </Button>
          </>
        );
      },
    },
  ];

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const cpf = data.get('cpf')?.toString();

    if (cpf) {
      setOpenLoading(true);
      const response = await getCertificatesFromCPFAPI(cpf);
      setOpenLoading(false);

      if (response instanceof ErrorAPI) {
        if (response.statusCode == 401) {
          navigate('/logout');
        }

        if (response.statusCode == 500) {
          setSnackbarMessage(response.message);
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
        }
      } else {
        const rows = response.map(
          (item: IStudentCertificate, index: number) => {
            return {
              id: index,
              pdf_url: item.pdf_url,
              name_student: item.name_student,
              classname: item.classname,
              solution_name: item.solution_name,
              supplier: item.supplier,
              pdf_base64: item.pdf_base64,
            };
          },
        );

        if (response.length === 0) {
          setSnackbarMessage('Não há registros de eventos para este período.');
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
          setCertificates([]);
          return;
        }
        setCertificates(rows);
      }
    } else {
      return;
    }
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        className={style.container_input}
      >
        <Typography variant="h2" gutterBottom>
          Busca de Certificados Online
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          Encontre seus certificados de forma rápida e fácil! Digite seu número
          de identificação para acessar e baixar seus certificados de maneira
          conveniente. Simplificamos o processo para tornar a validação de suas
          conquistas mais acessível do que nunca.
        </Typography>
        <TextField
          id="cpf"
          name="cpf"
          label="Digite seu CPF"
          variant="outlined"
          sx={{ width: '100%', mb: '1rem' }}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: TextMaskCustomCPF as any,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <Icon>{'search'}</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <DBGrid
          columnVisibilityModel={{
            id: false,
            uuid: false,
            pdf_url: false,
            pdf_base64: false,
          }}
          columns={columns}
          rows={certificates}
        />
        <Loading open={openLoading} />
        <SnackBar
          open={snackBarOpen}
          type={snackbarSeverity}
          message={snackBarMessage}
          onClose={() => {
            setSnackbarOpen(false);
          }}
        />
      </Box>
    </>
  );
};

export default ListCertificate;
