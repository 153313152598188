/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import dayjs from 'dayjs';
import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import Modal from '@mui/material/Modal';
import { useCallback, useEffect, useState } from 'react';
import { Box, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { List } from 'rsuite';
import { useTheme } from '@mui/material/styles';
import FilterToolbar from './FilterToobar';
import Loading from '../../components/Loading';
import { EventCalendar } from '../../../core/types/TEvent';
import { classSearchCalendar } from '../../../service/ClassroomService';
import SnackBar from '../../components/SnackBar';
import { useNavigate } from 'react-router-dom';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { DrawerProvider } from '../../contexts';
import styles from './style.module.css';
import { FilterData } from '../../../core/types/TFilterTypeCalendar';

dayjs.locale('pt-br');
require('globalize/lib/cultures/globalize.culture.pt-BR');

const localizer = dayjsLocalizer(dayjs);

const CalendarEvents = () => {
  const [eventSelect, setEventSelect] = useState<EventCalendar | null>(null);
  const [events, setEvents] = useState<EventCalendar[]>([]);
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const handleSearchData = async () => {
      const params = new URLSearchParams();
      params.set('all', 'classroom');
      setOpenLoading(true);
      const response = await classSearchCalendar(params);
      setOpenLoading(false);

      if (response instanceof ErrorAPI) {
        if (response.statusCode === 401) {
          navigate('/logout');
        } else if (response.statusCode === 500) {
          setSnackbarMessage(response.message);
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
        }
      } else {
        setEvents(response);
      }
    };

    handleSearchData();
  }, []);

  const lang = {
    'pt-BR': {
      week: 'Semana',
      work_week: 'Semana de trabalho',
      day: 'Dia',
      month: 'Mês',
      previous: 'Voltar',
      next: 'Próximo',
      today: 'Hoje',
      allDay: 'O dia todo',
      agenda: 'Agenda',
      showMore: (total: any) => `+${total} eventos`,
      noEventsInRange: 'Nenhum evento na agenda',
    },
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelectedEvent = (event: EventCalendar) => {
    setEventSelect(event);
    handleOpen();
  };

  const modalStyle = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    bgcolor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: 'auto',
    maxHeight: '80%',
    overflowY: 'auto',
  };

  const handleSearchData = async (data: FilterData) => {
    const params = new URLSearchParams();
    if (data.type === 'regional') {
      params.set('sebrae_origin', data.value);
    } else if (data.type === 'unidade') {
      params.set('unit_origin', data.value);
    } else {
      params.set('all', 'classroom');
    }
    setOpenLoading(true);
    const response = await classSearchCalendar(params);
    setOpenLoading(false);

    if (response instanceof ErrorAPI) {
      if (response.statusCode === 401) {
        navigate('/logout');
      } else if (response.statusCode === 500) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } else {
      setEvents(response);
    }
  };

  const eventPropGetter = useCallback(
    (event: EventCalendar) => ({
      ...(event.partitial_classroom_from_cron && {
        style: {
          backgroundColor: '#FFA000', // Amarelo âmbar
          borderStyle: 'dashed',
          borderColor: '#FF6F00', // Uma versão mais escura para a borda
          color: 'black', // Texto preto para contraste
        },
      }),
    }),
    [],
  );
  return (
    <div>
      <DrawerProvider>
        <SideBar>
          <Header title="Agenda de eventos" />
          <FilterToolbar onFilterChange={handleSearchData} />
          <Calendar
            localizer={localizer}
            events={events}
            onSelectEvent={(event) => handleSelectedEvent(event)}
            toolbar
            messages={lang['pt-BR']}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventPropGetter}
            step={15}
            timeslots={8}
            // dayPropGetter={customDayPropGetter}
            style={{ height: '50rem', margin: '1rem', padding: '1rem' }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={modalStyle}>
              <List>
                {eventSelect?.partitial_classroom_from_cron && (
                  <ListItem>
                    <ListItemText primary="Evento não construido ainda" />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary="Nome do evento"
                    secondary={eventSelect?.title}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Início"
                    secondary={dayjs(eventSelect?.start).format('DD/MM/YYYY')}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fim"
                    secondary={dayjs(eventSelect?.end).format('DD/MM/YYYY')}
                  />
                </ListItem>
                {eventSelect?.partitial_classroom_from_cron && (
                  <ListItemButton
                    onClick={() => {
                      navigate(`/finalize/classroom/${eventSelect?.uuid}`);
                    }}
                  >
                    <ListItemText primary="Finalizar construção da turma" />
                  </ListItemButton>
                )}
              </List>
            </Box>
          </Modal>
          <Loading open={openLoading} />
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={() => setSnackbarOpen(false)}
          />
        </SideBar>
      </DrawerProvider>
    </div>
  );
};

export default CalendarEvents;
