import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { TextField, Button, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import 'dayjs/locale/pt-br';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { IClassroom } from '../../../core/interfaces';

dayjs.locale('pt-br');
dayjs.extend(customParseFormat);

const dateSchema = z
  .string()
  .refine((val) => dayjs(val).isValid(), { message: 'Data inválida' });

const formSchema = z.object({
  className: z.string().min(1, 'Este campo é obrigatório'),
  solutionName: z.string().min(1, 'Este campo é obrigatório'),
  place: z.string().min(1, 'Este campo é obrigatório'),
  isAutoCheckin: z.boolean(),
  dates: z
    .object({
      init: dateSchema,
      end: dateSchema,
    })
    .refine(
      (data) =>
        dayjs(data.init).isSame(dayjs(data.end)) ||
        dayjs(data.init).isBefore(dayjs(data.end)),
      {
        message: 'A data inicial não pode ser maior que a data final',
        path: ['dates', 'init'], // Ajusta a mensagem de erro conforme necessário
      },
    ),
});

type IFormCheckinData = z.infer<typeof formSchema>;

interface IPropsFormCheckin {
  initialData: IClassroom;
  onChange: (value: IFormCheckinData) => void;
}

const FormCheckin: React.FC<IPropsFormCheckin> = ({
  initialData,
  onChange,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormCheckinData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      className: initialData.classname,
      solutionName: initialData.solution_name,
      place: initialData.place,
      isAutoCheckin: true,
      dates: {
        init: dayjs().format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },
    },
  });
  const [dataInitError, setDataInitError] = React.useState(false);
  const [dataEndError, setDataEndError] = React.useState(false);

  const onSubmit: SubmitHandler<IFormCheckinData> = (data) => {
    const newData = {
      ...data,
      dates: {
        ...data.dates,
        end: dayjs(data.dates.end)
          .hour(23)
          .minute(59)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss'),
      },
    };

    onChange(newData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="className"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nome da turma"
                margin="normal"
                fullWidth
                error={!!errors.className}
                helperText={errors.className?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="solutionName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nome da solução"
                margin="normal"
                fullWidth
                error={!!errors.solutionName}
                helperText={errors.solutionName?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="place"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Endereço do evento"
                margin="normal"
                fullWidth
                error={!!errors.place}
                helperText={errors.place?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptBR}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="dates.init"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label="Data Inicial"
                      inputFormat="DD/MM/YYYY"
                      disablePast={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={dataInitError || !!errors.dates?.init}
                          helperText={
                            dataInitError
                              ? 'Data inválida ou ano menor que 1900'
                              : errors.dates?.init?.message
                          }
                        />
                      )}
                      onChange={(newValue) => {
                        const isValidDate =
                          newValue && dayjs(newValue).isValid();
                        const isYearValid = dayjs(newValue).year() >= 1900;

                        if (isValidDate && isYearValid) {
                          field.onChange(dayjs(newValue).format('YYYY-MM-DD'));
                          setDataInitError(false);
                        } else {
                          setDataInitError(true);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dates.end"
                  control={control}
                  render={({ field: { onChange, value, ...fieldProps } }) => (
                    <DatePicker
                      {...fieldProps}
                      value={value}
                      label="Data Final"
                      disablePast={true}
                      inputFormat="DD/MM/YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.dates?.end || dataEndError}
                          helperText={
                            dataEndError
                              ? 'Data Final deve ser após a Data Inicial'
                              : errors.dates?.end?.message
                          }
                        />
                      )}
                      onChange={(newValue) => {
                        const isValidDate =
                          newValue && dayjs(newValue).isValid();
                        const isAfterStartDate = dayjs(newValue).isAfter(
                          dayjs(control._formValues.dates.init),
                        );

                        if (isValidDate && isAfterStartDate) {
                          // Aqui, a data final é ajustada para o final do dia com horário '23:59:00'
                          const adjustedEndValue = dayjs(newValue)
                            .hour(23)
                            .minute(59)
                            .second(0)
                            .format('YYYY-MM-DDTHH:mm:ss');
                          onChange(adjustedEndValue); // Use o valor ajustado para definir o valor final
                          setDataEndError(false);
                        } else {
                          setDataEndError(true);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={dataInitError || dataEndError} // Desabilita o botão se houver erros de data.
          >
            Iniciar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormCheckin;
