/* eslint-disable @typescript-eslint/no-unused-vars */
// Importações de Componentes e Módulos Internos
import HeaderDescription from '../../../components/HeaderDescription';
import DescriptionList from '../../../components/Description_list';
import Loading from '../../../components/Loading';
import NumberInput from '../../../components/NumberInput';

// Importações de Páginas Internas
import SearchProposal from '../../SearchProposal';
import SearchEvent from '../../SearchEvent';
import OptionClassroom from './OptionsClassroom';
import CreateClass from '../CreateClass';
import FormHelperText from '@mui/material/FormHelperText';

import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  Divider,
  Button,
  Modal,
  Box,
  Stack,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// Importações de Serviços e Tipos
import {
  searchClassroomFromProposal,
  searchClassroomFromIntegra,
  searchClassroomFromLocal,
} from '../../../../service/ClassroomService';
import {
  IClassroom,
  ICompositeEvent,
  // ICompositeEvent,
  IFormCheckinData,
  IPlacePerformance,
  ISebrae,
  IUnit,
} from '../../../../core/interfaces';
import { TOptionsClassroom, TSeachClassroom } from '../../../../core/types';
import ErrorAPI from '../../../../core/models/ErrorAPI';

// Importações de Utilitários
import { useState } from 'react';
import { isEmpty } from '../../../../core/utils';
// import CompositeEventSelect from './CompositeEventSelect';
import ListClassroomsIntegra from '../../ListClassroomsIntegra';
import GenericMenu from '../../../components/GenericMenu';
import CompositeEventSelect from './CompositeEventSelect';
import { useNavigate } from 'react-router-dom';

interface IPropsSearchClassroom {
  initClassroom: IClassroom;
  onChange: (value: IClassroom) => void;
}
const SearchClassroom: React.FC<IPropsSearchClassroom> = ({
  initClassroom,
  onChange,
}) => {
  const [classroom, setClassroom] = useState<IClassroom>(initClassroom);
  const [nameEvent, setNameEvent] = useState<string>('');
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [typeSearch, setTypeSearch] = useState<TSeachClassroom>(
    initClassroom.from_origin ? initClassroom.from_origin : 'PROPOSTA',
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeOriginSebrae, setTypeOriginSebrae] = useState<ISebrae | undefined>(
    classroom.sebrae_origin,
  );
  const [typeOriginUnit, setTypeOriginUnit] = useState<IUnit | undefined>(
    classroom.unit_origin,
  );

  const navigate = useNavigate();

  const [placePerformance, setPlacePerformance] = useState<
    IPlacePerformance | undefined
  >(classroom.place_performance);
  const [allPlacePerformance, setallPlacePerformance] = useState<
    IPlacePerformance[]
  >(initClassroom.all_place_performance as IPlacePerformance[]);
  const [hintError, setHintError] = useState<string>('');
  const [errorHint, setErrorHint] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<string>(
    classroom.code_search ? classroom.code_search : '',
  );
  const [initOptions, setInitOptions] = useState<TOptionsClassroom>({
    autoFinished: initClassroom.auto_finished
      ? initClassroom.auto_finished
      : false,
    autoSend: initClassroom.auto_send ? initClassroom.auto_send : false,
    supplierPermission:
      initClassroom.is_auto_checkin !== undefined
        ? initClassroom.is_auto_checkin
        : false,
    eventComposite:
      initClassroom.is_event_Composite !== undefined
        ? initClassroom.is_event_Composite
        : false,
    eventReceipt:
      initClassroom.is_event_receipt !== undefined
        ? initClassroom.is_event_receipt
        : false,
    freeClassCapacity:
      initClassroom.free_class_capacity !== undefined
        ? initClassroom.free_class_capacity
        : 0,
    isLimitedEnrollment:
      initClassroom.is_limited_enrollment !== undefined
        ? initClassroom.is_limited_enrollment
        : false,
    requireCertificate:
      initClassroom.require_certificate !== undefined
        ? initClassroom.require_certificate
        : false,
    belongsToABigEvent:
      initClassroom.belongs_to_a_big_event !== undefined
        ? initClassroom.belongs_to_a_big_event
        : false,
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalEvent, setOpenModalEvent] = useState<boolean>(false);
  const Component = {
    INTEGRA: SearchEvent,
    PROPOSTA: SearchProposal,
    LOCAL: CreateClass,
  }[typeSearch];

  const SearchData = {
    INTEGRA: searchClassroomFromIntegra,
    PROPOSTA: searchClassroomFromProposal,
    LOCAL: searchClassroomFromLocal,
  }[typeSearch];

  const handleDefinedError = (menssage: string, active: boolean) => {
    setHintError(menssage);
    setErrorHint(active);
  };

  const handleOnchageOption = (value: TOptionsClassroom) => {
    classroom.auto_finished = value.autoFinished;
    classroom.is_auto_checkin = value.supplierPermission;
    classroom.auto_send = value.autoSend;
    classroom.is_event_Composite = value.eventComposite;
    classroom.is_event_receipt = value.eventReceipt;
    classroom.free_class_capacity = value.freeClassCapacity;
    classroom.is_limited_enrollment = value.isLimitedEnrollment;
    classroom.require_certificate = value.requireCertificate;
    classroom.belongs_to_a_big_event = value.belongsToABigEvent;

    const optionsClassrom: TOptionsClassroom = {
      autoFinished: value.autoFinished,
      supplierPermission: value.supplierPermission,
      autoSend: value.autoSend,
      eventComposite: value.eventComposite,
      eventReceipt: value.eventReceipt,
      freeClassCapacity: value.freeClassCapacity,
      isLimitedEnrollment: value.isLimitedEnrollment,
      requireCertificate: value.requireCertificate,
      belongsToABigEvent: value.belongsToABigEvent,
    };
    onChange(classroom);
    setInitOptions(optionsClassrom);
  };

  const handleDefaultOption = (classroom: IClassroom) => {
    const optionsClassrom: TOptionsClassroom = {
      autoFinished: false,
      supplierPermission:
        classroom.is_auto_checkin === undefined
          ? false
          : classroom.is_auto_checkin,
      autoSend: classroom.auto_send === undefined ? false : classroom.auto_send,
      eventComposite:
        classroom.is_event_Composite === undefined
          ? false
          : classroom.is_event_Composite,
      eventReceipt:
        classroom.is_event_Composite === undefined
          ? false
          : classroom.is_event_Composite,
      freeClassCapacity:
        classroom.free_class_capacity === undefined
          ? 999
          : classroom.free_class_capacity,
      isLimitedEnrollment:
        classroom.is_limited_enrollment === undefined
          ? false
          : classroom.is_limited_enrollment,
      requireCertificate:
        classroom.require_certificate === undefined
          ? false
          : classroom.require_certificate,
      belongsToABigEvent:
        classroom.belongs_to_a_big_event === undefined
          ? false
          : classroom.belongs_to_a_big_event,
    };
    setInitOptions(optionsClassrom);
  };

  const handleSetValues = (classroom: IClassroom) => {
    if (!isEmpty(classroom)) {
      setClassroom(classroom);
      setDefaultValue(classroom.code_search ? classroom.code_search : '');
      handleDefinedError('', false);
      handleDefaultOption(classroom);
      onChange(classroom);
    } else {
      setClassroom({} as IClassroom);
      handleDefinedError('Código invalido ou inexistente', true);
    }
  };

  const handleGetValue = async (value: string & IFormCheckinData) => {
    setOpenLoading(true);
    handleClearChange();
    const classroom = await SearchData(value);
    setOpenLoading(false);
    if (classroom instanceof ErrorAPI) {
      if (classroom?.extras?.is_partitial) {
        navigate(`/finalize/classroom/${classroom.extras?.uuid}`);
      }
      handleDefinedError(classroom.message, true);
    } else {
      handleSetValues(classroom);
    }
  };

  const handleGetValueCompositeEvent = (value: object[]) => {
    if (value.length > 0) {
      classroom.composite_event = value[0] as ICompositeEvent;
      setNameEvent(classroom.composite_event.name);
    }
  };

  const handleClearChange = () => {
    setClassroom({} as IClassroom);
    setDefaultValue('');
  };

  const handleChangeTypeSearch = (event: SelectChangeEvent) => {
    const typeSelect = event.target.value as string;
    handleClearChange();
    if (typeSelect === 'INTEGRA') {
      setTypeSearch('INTEGRA');
    } else if (typeSelect === 'LOCAL') {
      setTypeSearch('PROPOSTA');
      setTypeSearch('LOCAL');
    } else if (typeSelect === 'PROPOSTA') {
      setTypeSearch('PROPOSTA');
    }
  };

  const handleChangeOriginSebrae = (event: SelectChangeEvent) => {
    const typeSelect = event.target.value as string;
    const sebraeOrigin = classroom.all_sebrae?.find(
      (value) => value.id === typeSelect,
    );
    const sebraesPlace = classroom.all_place_performance?.filter(
      (value) => value.id === sebraeOrigin?.id,
    );
    setallPlacePerformance(sebraesPlace!);
    // classroom.all_place_performance = sebraesPlace;
    classroom.sebrae_origin = sebraeOrigin;
    onChange(classroom);
    setTypeOriginSebrae(sebraeOrigin);
  };

  const handleChangeOriginUnit = (event: SelectChangeEvent) => {
    const typeSelect = event.target.value as string;
    const unitOrigin = classroom.all_unit?.find(
      (value) => value.id === typeSelect,
    );
    classroom.unit_origin = unitOrigin;
    onChange(classroom);
    setTypeOriginUnit(unitOrigin);
  };

  const handleChangePlacePerformance = (event: SelectChangeEvent) => {
    const typeSelect = event.target.value as string;
    const place_performance = classroom.all_place_performance?.find(
      (value) => value.id === typeSelect,
    );
    classroom.place_performance = place_performance;
    onChange(classroom);
    setPlacePerformance(place_performance);
  };
  const handleModal = () => setOpenModal((prevent) => !prevent);

  const styleModal = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Sombra do modal
    position: 'fixed', // Define a posição como fixa
    top: '1vh', // Espaço no topo
    right: '1vw', // Espaço à direita
    bottom: '1vh', // Espaço na parte inferior
    left: '1vw', // Espaço à esquerda
    maxHeight: '200vh', // Altura máxima do modal
    maxWidth: '200vw', // Largura máxima do modal
  };

  const innerContainerStyle = {
    padding: '20px', // Ajuste o espaçamento interno conforme desejado
    width: '100%', // Ocupa toda a largura disponível
  };

  const onCallbackGetEvent = (obj: object) => {
    const event = obj as IClassroom;
    setDefaultValue(event.code_search ? event.code_search : '');
    handleModal();
  };
  return (
    <>
      <Loading open={openLoading} />
      <HeaderDescription
        title="Gerar Evento"
        subtitle="Encontre um Evento por Código ou Crie uma Nova Turma"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          margin: '1rem',
        }}
      >
        {classroom.belongs_to_a_big_event && !isEmpty(classroom) && (
          <GenericMenu
            title="Opção de evento"
            menuOptions={[
              {
                label: 'Escolher um evento',
                onClick: () => setOpenModalEvent(true),
              },
            ]}
          />
        )}
      </Box>
      {isEmpty(classroom) && (
        <>
          <div style={{ margin: '1rem', display: 'flex' }}>
            <FormControl fullWidth>
              <InputLabel id="typeDocumentLabel">
                Selecione a base de origem
              </InputLabel>
              <Select
                labelId="typeDocument"
                id="typeDocument"
                label="Tipo de documento"
                onChange={handleChangeTypeSearch}
                value={typeSearch}
              >
                <MenuItem value={'PROPOSTA'}>Proposta</MenuItem>
                <MenuItem value={'INTEGRA'}>
                  Sistema de Atendimento e Relacionamento do SEBRAE (SAS)
                </MenuItem>
                <MenuItem value={'LOCAL'}>Local</MenuItem>
              </Select>
            </FormControl>
          </div>
          {typeSearch === 'INTEGRA' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '1rem',
              }}
            >
              <Button onClick={handleModal} startIcon={<SearchIcon />}>
                Buscar turmas por periodo
              </Button>
            </div>
          )}
          <Component
            initialData={initClassroom}
            openDialogInit={true}
            defaultValue={defaultValue}
            getValue={handleGetValue}
            hintError={errorHint}
            hintErrorDescription={hintError}
            codeSearchModal={defaultValue}
          />
        </>
      )}

      <Modal open={openModal} onClose={handleModal}>
        <Box sx={styleModal}>
          <div style={innerContainerStyle}>
            <ListClassroomsIntegra onSelectCallback={onCallbackGetEvent} />
          </div>
        </Box>
      </Modal>

      {(classroom.is_event_Composite || nameEvent) && (
        <Divider>Evento principal {nameEvent}</Divider>
      )}
      {!isEmpty(classroom) && (
        <>
          <>
            <div style={{ margin: '1rem', display: 'flex' }}>
              <FormControl fullWidth>
                <InputLabel id="originSebrae">Sebrae de origem</InputLabel>
                <Select
                  labelId="originSebrae"
                  id="originSebrae"
                  label="Sebrae de origem"
                  onChange={handleChangeOriginSebrae}
                  value={typeOriginSebrae ? typeOriginSebrae.id : ''}
                >
                  {classroom.all_sebrae?.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ margin: '1rem', display: 'flex' }}>
              <FormControl fullWidth>
                <InputLabel id="originUnit">Unidade de origem</InputLabel>
                <Select
                  labelId="originUnit"
                  id="originUnit"
                  label="Unidade de origem"
                  onChange={handleChangeOriginUnit}
                  value={typeOriginUnit ? typeOriginUnit.id : ''}
                >
                  {classroom.all_unit?.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ margin: '1rem', display: 'flex' }}>
              <FormControl fullWidth>
                <InputLabel id="placePerformance">
                  Local de realização
                </InputLabel>
                <Select
                  labelId="placePerformance"
                  id="placePerformance"
                  label="Local de realização"
                  onChange={handleChangePlacePerformance}
                  value={placePerformance ? placePerformance.id : ''}
                >
                  {allPlacePerformance?.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
          <CompositeEventSelect
            getValue={handleGetValueCompositeEvent}
            isOpen={openModalEvent}
            onClose={() => setOpenModalEvent(false)}
          />
          <OptionClassroom
            typeClassroom={typeSearch}
            initOptions={initOptions}
            onChange={handleOnchageOption}
          />

          {classroom.is_limited_enrollment && (
            <Stack
              sx={{
                margin: '1rem',
              }}
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <NumberInput
                initialValue={classroom.free_class_capacity}
                onChange={(number) => {
                  if (number !== '') classroom.free_class_capacity = number;
                }}
                maxValue={
                  classroom.from_origin === 'PROPOSTA'
                    ? 9999
                    : classroom.free_class_capacity
                }
                label={'Digite a quantidade alunos limitado a essa turma'}
              />
            </Stack>
          )}
          <DescriptionList value={classroom} />
        </>
      )}
    </>
  );
};

export default SearchClassroom;
