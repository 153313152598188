import { AxiosProgressEvent } from 'axios';
import { IClassroom, IFormCheckinData } from '../core/interfaces';
import { ClassroomOperationSearch } from '../infra/api/ClassroomOperationSearch';
import { ClassroomOperationAPI } from '../infra/api/ClassroomOperationAPI';
import { ClassroomOperationCertificateAPI } from '../infra/api/ClassroomOperationCertificateAPI';
import { ClassroomOperationFile } from '../infra/api/ClassroomOperationFile';
import { ClassroomOperationGenerate } from '../infra/api/ClassroomOperationGenerate';

export async function searchClassroomFromProposal(number_proposta: string) {
  const instaceInfra = ClassroomOperationSearch();
  const classroom =
    await instaceInfra.searchClassroomFromProposalAPI(number_proposta);

  return classroom;
}

export async function searchClassroomFromIntegra(code_event: string) {
  const instaceInfra = ClassroomOperationSearch();
  const classroom =
    await instaceInfra.searchClassroomFromIntegraAPI(code_event);

  return classroom;
}

export async function generateClassroomAPI(classroom: IClassroom) {
  const instaceInfra = ClassroomOperationGenerate();
  const newclassroom = await instaceInfra.generateClassroomAPI(classroom);

  return newclassroom;
}

export async function searchClassrooms(params?: URLSearchParams) {
  const instaceInfra = ClassroomOperationSearch();
  const classrooms = await instaceInfra.searchClassrooms(params);

  return classrooms;
}

export async function classroomAddStudent(data: object) {
  const instaceInfra = ClassroomOperationAPI();
  const statusCode = await instaceInfra.classroomAddStudent(data);

  return statusCode;
}

export async function classroomInitValidate(
  classroom_id: string | undefined,
  init: boolean,
) {
  const instaceInfra = ClassroomOperationAPI();
  const statusCode = await instaceInfra.classroomInitValidate(
    classroom_id,
    init,
  );

  return statusCode;
}

export async function classroomFinalize(
  classroom_id: string | undefined,
  sendFiles: boolean,
) {
  const instaceInfra = ClassroomOperationAPI();
  const statusCode = await instaceInfra.classroomFinalize(
    classroom_id,
    sendFiles,
  );

  return statusCode;
}

export async function classroomClosed(classroom_id: string | undefined) {
  const instaceInfra = ClassroomOperationAPI();
  const statusCode = await instaceInfra.classroomClosed(classroom_id);

  return statusCode;
}

export async function classData(classroom_id: string | undefined) {
  const instaceInfra = ClassroomOperationSearch();
  const classData = await instaceInfra.getInformationClassroom(classroom_id);

  return classData;
}

export async function classSendPhoneQRcode(classroom_id: string | undefined) {
  const instaceInfra = ClassroomOperationAPI();
  const classData = await instaceInfra.classSendPhoneQRcode(classroom_id);

  return classData;
}

export async function classSendEmailQRcode(classroom_id: string | undefined) {
  const instaceInfra = ClassroomOperationAPI();
  const classData = await instaceInfra.classSendEmailQRcode(classroom_id);

  return classData;
}

export async function searchClassroomFromLocal(classroom: IFormCheckinData) {
  const instaceInfra = ClassroomOperationSearch();
  const classData = await instaceInfra.searchClassroomFromLocalAPI(classroom);

  return classData;
}

export async function classSearchSolution(params?: URLSearchParams) {
  const instaceInfra = ClassroomOperationSearch();
  const classData = await instaceInfra.classSearchSolution(params);

  return classData;
}

export async function classSendFiles(
  classroom_id: string | undefined,
  files: File[],
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) {
  const instaceInfra = ClassroomOperationFile();
  const classData = await instaceInfra.classSendFiles(
    classroom_id,
    files,
    onUploadProgress,
  );

  return classData;
}

export async function classDownloadFile(url: string, name: string) {
  const instaceInfra = ClassroomOperationFile();
  const classData = await instaceInfra.classDownloadFile(url, name);

  return classData;
}

export async function classDeleteFiles(
  classroom_id: string | undefined,
  file: File,
) {
  const instaceInfra = ClassroomOperationFile();
  const classData = await instaceInfra.classDeleteFiles(classroom_id, file);

  return classData;
}

export async function classRenameFiles(
  classroom_id: string | undefined,
  file: File,
) {
  const instaceInfra = ClassroomOperationFile();
  const classData = await instaceInfra.classRenameFiles(classroom_id, file);

  return classData;
}

export async function classSendLink(
  classroom_id: string | undefined,
  link: string,
) {
  const instaceInfra = ClassroomOperationFile();
  const classData = await instaceInfra.classSendLink(classroom_id, link);

  return classData;
}

export async function classSendCertificate(
  verseActive: boolean,
  name_file: string,
  htmlFront: string,
  htmlBack: string,
  imageFront: File | null,
  imageBack: File | null,
  contentEditorFront: string,
  contentEditorBack: string | null,
) {
  const instaceInfra = ClassroomOperationCertificateAPI();
  const classData = await instaceInfra.classSendCertificate(
    verseActive,
    name_file,
    htmlFront,
    htmlBack,
    imageFront,
    imageBack,
    contentEditorFront,
    contentEditorBack,
  );

  return classData;
}

export async function getCertificate() {
  const instaceInfra = ClassroomOperationCertificateAPI();
  const classData = await instaceInfra.getCertificate();

  return classData;
}

export async function updateCertificates(
  uuid: string,
  verseActive: boolean,
  name_file: string,
  htmlFront: string,
  htmlBack: string,
  imageFront: File | null,
  imageBack: File | null,
  contentEditorFront: string,
  contentEditorBack: string | null,
) {
  const instaceInfra = ClassroomOperationCertificateAPI();
  const classData = await instaceInfra.updateCertificate(
    uuid,
    verseActive,
    name_file,
    htmlFront,
    htmlBack,
    imageFront,
    imageBack,
    contentEditorFront,
    contentEditorBack,
  );

  return classData;
}

export async function classSearchSolutions(params?: URLSearchParams) {
  const instaceInfra = ClassroomOperationSearch();
  const classData = await instaceInfra.classSearchSolutions(params);

  return classData;
}

export async function getPreviewCertificate(data: object) {
  const instaceInfra = ClassroomOperationCertificateAPI();
  const dataCertificate = await instaceInfra.getPreviewCertificate(data);

  return dataCertificate;
}

export async function searchClassroomsFromIntegraAPI(params?: URLSearchParams) {
  const instaceInfra = ClassroomOperationSearch();
  const classrooms = await instaceInfra.searchClassroomsFromIntegraAPI(params);

  return classrooms;
}

export async function classSendMessagePhone(
  classroom_id: string | undefined,
  mensagem: string,
) {
  const instaceInfra = ClassroomOperationAPI();
  const classData = await instaceInfra.classSendMessagePhone(
    classroom_id,
    mensagem,
  );

  return classData;
}

export async function classSyncStudents(classroom_id: string | undefined) {
  const instaceInfra = ClassroomOperationAPI();
  const classData = await instaceInfra.classSyncStudents(classroom_id);

  return classData;
}

export async function reopenClass(classroom_id: string | undefined) {
  const instaceInfra = ClassroomOperationAPI();
  const classData = await instaceInfra.reopenClass(classroom_id);

  return classData;
}

export async function classSendEventProof(
  classroom_id: string | undefined,
  files: File[],
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) {
  const instaceInfra = ClassroomOperationFile();
  const classData = await instaceInfra.classSendEventProof(
    classroom_id,
    files,
    onUploadProgress,
  );

  return classData;
}

export async function classDeleteEventProof(
  classroom_id: string | undefined,
  uuid_file_proof: string,
) {
  const instaceInfra = ClassroomOperationFile();
  const classData = await instaceInfra.classDeleteEventProof(
    classroom_id,
    uuid_file_proof,
  );

  return classData;
}

export async function classSearchCalendar(params?: URLSearchParams) {
  const instaceInfra = ClassroomOperationSearch();
  const classData = await instaceInfra.searchClassroomsCalendar(params);

  return classData;
}

export async function classroomSendFlowStudent(data: object) {
  const instaceInfra = ClassroomOperationAPI();
  const classData = await instaceInfra.classroomSendFlowStudent(data);

  return classData;
}

export async function searchClassroomsBuildInIntegra(
  next?: string,
  previous?: string,
  params?: URLSearchParams,
) {
  const instaceInfra = ClassroomOperationSearch();
  const classData = await instaceInfra.searchClassroomsBuildInIntegra(
    next,
    previous,
    params,
  );
  return classData;
}

export async function getByIDClassroomIntegraTemp(classroom_id: string) {
  const instaceInfra = ClassroomOperationSearch();
  const classData =
    await instaceInfra.getByIDClassroomIntegraTemp(classroom_id);
  return classData;
}

export async function classroomUpdateIntegraTemp(
  classroom_id: string,
  data: object,
) {
  const instaceInfra = ClassroomOperationAPI();
  const classData = await instaceInfra.classroomUpdateIntegraTemp(
    classroom_id,
    data,
  );

  return classData;
}
