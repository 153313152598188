/* eslint-disable prefer-const */
import { get, post } from '../adapters/xhr';
import {
  IClassroom,
  ISupplier,
  IStudent,
  IShift,
  IValidClass,
  IFormCheckinData,
  InitDateClassroom,
  ISearchSOL,
  ISebrae,
  IUnit,
  IPlacePerformance,
  IClassroomPagination,
} from '../../core/interfaces';
import { AxiosError } from 'axios';
import { EventCalendar } from '../../core/types';
import ErrorAPI from '../../core/models/ErrorAPI';

export const ClassroomOperationSearch = () => ({
  searchClassroomFromProposalAPI: async (number_proposta: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;

      let response = await get(
        `${BaseURL}/api/v1/classroom/get/proposal/${number_proposta}`,
      );
      let classroom: IClassroom;
      let suppliers: Array<ISupplier> = [];
      let students: Array<IStudent> = [];
      let shfits: Array<IShift> = [];
      let sebraes: Array<ISebrae> = [];
      let units: Array<IUnit> = [];
      let places_performance: Array<IPlacePerformance> = [];

      if (response?.status === 200) {
        let m = response?.data['suppliers'];
        for (let i = 0; i < m.length; i++) {
          let supplier: ISupplier;
          supplier = {
            uuid: m[i].uuid,
            id: m[i].id,
            name: m[i].name,
            cnpj_empresa: m[i].cnpj_empresa,
            company: m[i].company,
            cpf: m[i].cpf,
            phone: m[i].phone,
            email: m[i].email,
          };
          suppliers.push(supplier);
        }

        let s = response?.data['students'];
        for (let i = 0; i < s.length; i++) {
          let student: IStudent;
          student = {
            id: s[i].id,
            name: s[i].name,
            CPF: s[i].CPF,
            CNPJ: s[i].CNPJ,
            company_name: s[i].company_name,
            email: s[i].email,
            whatsapp: s[i].whatsapp,
            checkin: s[i].checkin,
            checkin_realized: s[i].checkin_realized,
            company: s[i].company,
            CodParceiroPJ: s[i].CodParceiroPJ,
            CodParceiro: s[i].CodParceiro,
          };
          students.push(student);
        }

        let st = response?.data['shifts'];
        for (let i = 0; i < st.length; i++) {
          let shift: IShift;
          shift = {
            id: st[i].id,
            name: st[i].name,
            init_period: st[i].init_period,
            end_period: st[i].end_period,
            active: false,
          };
          shfits.push(shift);
        }

        let all_sebrae = response?.data['all_sebrae'];
        for (let i = 0; i < all_sebrae.length; i++) {
          let sebrae: ISebrae;
          sebrae = {
            id: all_sebrae[i].id,
            name: all_sebrae[i].name,
            place: all_sebrae[i].place,
            cnpj: all_sebrae[i].cnpj,
          };
          sebraes.push(sebrae);
        }

        let all_place_performance = response?.data['all_place_performance'];
        for (let i = 0; i < all_place_performance.length; i++) {
          let place_performance: IPlacePerformance;
          place_performance = {
            uuid: all_place_performance[i].id,
            id: all_place_performance[i].sebrae_obj.id,
            name: all_place_performance[i].name,
          };
          places_performance.push(place_performance);
        }

        let all_unit = response?.data['all_unit'];
        for (let i = 0; i < all_unit.length; i++) {
          let unit: IUnit;
          unit = {
            id: all_unit[i].id,
            name: all_unit[i].name,
            identification: all_unit[i].identification,
            sebrae_id: all_unit[i].sebrae_id,
          };
          units.push(unit);
        }
        const InitialDateClassroom: InitDateClassroom = {
          eventDurantionDays: response?.data['num_visist'],
          eventDurationDates: response?.data['start_date_event']
            ? `${response?.data['start_date_event']} - ${response?.data['end_date_event']}`
            : undefined,
        };

        classroom = {
          classname: response?.data['classname'],
          solution_code: response?.data['solution_code'],
          solution_name: response?.data['solution_name'],
          proposal_id: response?.data['proposal_id'],
          proposal_code: response?.data['proposal_code'],
          is_collective: response?.data['is_collective'],
          is_free: response?.data['is_free'],
          is_the_class_closed: response?.data['is_the_class_closed'],
          is_internal_project: response?.data['is_internal_project'],
          proposal_value: response?.data['proposal_value'],
          place: response?.data['place'],
          finished_event: response?.data['finished_event'],
          auto_send: false,
          is_auto_checkin: true,
          auto_finished: false,
          is_event_receipt: false,
          require_certificate: false,
          free_class_capacity: response?.data['free_class_capacity'],
          is_limited_enrollment: response?.data['is_limited_enrollment'],
          shifts: shfits,
          suppliers: suppliers,
          certificate: null,
          students: students,
          number_contract: response?.data['number_contract'],
          start_date_event: response?.data['start_date_event'],
          end_date_event: response?.data['end_date_event'],
          from_origin: response?.data['from_origin'],
          checkins_required: 0,
          sol_methodology: response?.data['sol_methodology'],
          suppliersSelect: [],
          checkin_certificate: [],
          InitialDateClassroom: InitialDateClassroom,
          all_sebrae: sebraes,
          all_unit: all_unit,
          all_place_performance: places_performance,
          code_search: response?.data['code_search'],
        };
        return classroom;
      } else {
        return {} as IClassroom;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;

        if (response) {
          switch (response.status) {
            case 401:
              return new ErrorAPI(response.status, response.statusText);
            case 400:
              return new ErrorAPI(response.status, response.data?.msg);
            // Add more cases if needed
          }
        } else {
          // Handle AxiosError without a response (e.g., network error)
          console.error('AxiosError without response:', error);
        }
      }

      // Handle other types of errors
      console.error('Unhandled error:', error);
      return {} as IClassroom;
    }
  },
  searchClassroomFromIntegraAPI: async (code_event: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;

      let response = await get(
        `${BaseURL}/api/v1/classroom/get/integra/${code_event}`,
      );

      let classroom: IClassroom;
      let suppliers: Array<ISupplier> = [];
      let students: Array<IStudent> = [];
      let shfits: Array<IShift> = [];
      let sebraes: Array<ISebrae> = [];
      let units: Array<IUnit> = [];
      let places_performance: Array<IPlacePerformance> = [];

      let all_sebrae = response?.data['all_sebrae'];
      for (let i = 0; i < all_sebrae.length; i++) {
        let sebrae: ISebrae;
        sebrae = {
          id: all_sebrae[i].id,
          name: all_sebrae[i].name,
          place: all_sebrae[i].place,
          cnpj: all_sebrae[i].cnpj,
        };
        sebraes.push(sebrae);
      }

      let all_unit = response?.data['all_unit'];
      for (let i = 0; i < all_unit.length; i++) {
        let unit: IUnit;
        unit = {
          id: all_unit[i].id,
          name: all_unit[i].name,
          identification: all_unit[i].identification,
          sebrae_id: all_unit[i].sebrae_id,
        };
        units.push(unit);
      }
      let all_place_performance = response?.data['all_place_performance'];
      for (let i = 0; i < all_place_performance.length; i++) {
        let place_performance: IPlacePerformance;
        place_performance = {
          uuid: all_place_performance[i].id,
          id: all_place_performance[i].sebrae_obj.id,
          name: all_place_performance[i].name,
          sebrae_origin: all_place_performance[i].sebrae_obj.id,
        };
        places_performance.push(place_performance);
      }
      let m = response?.data['suppliers'];
      for (let i = 0; i < m.length; i++) {
        let supplier: ISupplier;
        supplier = {
          uuid: m[i].uuid,
          id: m[i].id,
          name: m[i].name,
          cnpj_empresa: m[i].cnpj_empresa,
          company: m[i].company,
          cpf: m[i].cpf,
          phone: m[i].phone,
          email: m[i].email,
        };
        suppliers.push(supplier);
      }

      let s = response?.data['students'];
      for (let i = 0; i < s.length; i++) {
        let student: IStudent;
        student = {
          id: s[i].id,
          name: s[i].name,
          CPF: s[i].CPF,
          CNPJ: s[i].CNPJ,
          company_name: s[i].company_name,
          email: s[i].email,
          whatsapp: s[i].whatsapp,
          checkin: s[i].checkin,
          checkin_realized: s[i].checkin_realized,
          company: s[i].company,
          CodParceiroPJ: s[i].CodParceiroPJ,
          CodParceiro: s[i].CodParceiro,
        };
        students.push(student);
      }

      let st = response?.data['shifts'];
      for (let i = 0; i < st.length; i++) {
        let shift: IShift;
        shift = {
          id: st[i].id,
          name: st[i].name,
          init_period: st[i].init_period,
          end_period: st[i].end_period,
          active: false,
        };
        shfits.push(shift);
      }

      classroom = {
        classname: response?.data['classname'],
        solution_code: response?.data['solution_code'],
        solution_name: response?.data['solution_name'],
        proposal_id: response?.data['proposal_id'],
        proposal_code: response?.data['proposal_code'],
        is_collective: response?.data['is_collective'],
        is_free: response?.data['is_free'],
        is_the_class_closed: response?.data['is_the_class_closed'],
        is_internal_project: response?.data['is_internal_project'],
        proposal_value: response?.data['proposal_value'],
        place: response?.data['place'],
        finished_event: response?.data['finished_event'],
        auto_send: false,
        is_auto_checkin: true,
        auto_finished: false,
        is_event_receipt: false,
        require_certificate: false,
        free_class_capacity: response?.data['free_class_capacity'],
        is_limited_enrollment: response?.data['is_limited_enrollment'],
        certificate: null,
        shifts: shfits,
        suppliers: suppliers,
        students: students,
        number_contract: response?.data['number_contract'],
        start_date_event: response?.data['start_date_event'],
        end_date_event: response?.data['end_date_event'],
        from_origin: response?.data['from_origin'],
        event_id: response?.data['event_id'],
        event_value: response?.data['event_value'],
        checkins_required: 0,
        all_sebrae: sebraes,
        all_unit: all_unit,
        all_place_performance: places_performance,
        suppliersSelect: [],
        checkin_certificate: [],
        code_search: response?.data['code_search'],
      };
      return classroom;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 400:
            errorAPI = new ErrorAPI(
              response.status,
              response.data?.msg,
              response.data?.extras,
            );
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.message);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'O código é inválido ou inexistente. Por favor, verifique e forneça um código válido.',
            );
            break;
        }

        return errorAPI;
      }

      console.error('Unhandled error:', error);
      // Handle or log any other specific cases if needed
      return {} as IClassroom;
    }
  },
  searchClassroomFromLocalAPI: async (obj: IFormCheckinData) => {
    let shifts: Array<IShift> = [];
    let sebraes: Array<ISebrae> = [];
    let units: Array<IUnit> = [];
    let places_performance: Array<IPlacePerformance> = [];

    const date = obj.dates as { [key: string]: string };

    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    const responseShifts = await get(`${BaseURL}/api/v1/operation/shifts`);
    let shfitsResponse = responseShifts?.data as IShift[];

    for (let i = 0; i < shfitsResponse.length; i++) {
      let shift: IShift;
      shift = {
        id: shfitsResponse[i].id,
        name: shfitsResponse[i].name,
        init_period: shfitsResponse[i].init_period,
        end_period: shfitsResponse[i].end_period,
        active: false,
      };
      shifts.push(shift);
    }

    const responseSebraes = await get(`${BaseURL}/api/v1/search/sebraes`);
    let sebraesResponse = responseSebraes?.data as ISebrae[];
    let all_sebrae = sebraesResponse;
    for (let i = 0; i < all_sebrae.length; i++) {
      let sebrae: ISebrae;
      sebrae = {
        id: all_sebrae[i].id,
        name: all_sebrae[i].name,
        place: all_sebrae[i].place,
        cnpj: all_sebrae[i].cnpj,
      };
      sebraes.push(sebrae);
    }

    const responseUnit = await get(`${BaseURL}/api/v1/search/units`);
    let unitsResponse = responseUnit?.data as IUnit[];
    for (let i = 0; i < unitsResponse.length; i++) {
      let unit: IUnit;
      unit = {
        id: unitsResponse[i].id,
        name: unitsResponse[i].name,
        identification: unitsResponse[i].identification,
        sebrae_id: unitsResponse[i].sebrae_id,
      };
      units.push(unit);
    }

    const response = await get(`${BaseURL}/api/v1/place/performance/get/all`);
    let responsePlacePerformance = response?.data as IPlacePerformance[];
    for (let i = 0; i < responsePlacePerformance.length; i++) {
      let place_performance: IPlacePerformance;
      place_performance = {
        uuid: responsePlacePerformance[i].uuid,
        id: responsePlacePerformance[i].sebrae_obj?.id,
        name: responsePlacePerformance[i].name,
      };
      places_performance.push(place_performance);
    }

    let classroom: IClassroom = {
      classname: obj.className,
      solution_name: obj.solutionName,
      auto_send: obj.autoSend,
      is_collective: true,
      is_auto_checkin: obj.isAutoCheckin,
      is_free: true,
      is_internal_project: true,
      finished_event: false,
      start_date_event: date['init'],
      end_date_event: date['end'],
      place: obj.place,
      is_event_receipt: false,
      free_class_capacity: 9999,
      certificate: null,
      sol_methodology: null,
      from_origin: 'LOCAL',
      checkins_required: 0,
      solution_code: 0,
      proposal_id: null,
      proposal_code: null,
      all_sebrae: sebraes,
      all_unit: units,
      all_place_performance: places_performance,
      shifts: shifts,
      checkin_certificate: [],
      students: [],
      number_contract: null,
      suppliers: [],
      suppliersSelect: [],
    };
    return classroom;
  },
  searchClassrooms: async (params?: URLSearchParams) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/list`,
        undefined,
        params,
      );
      if (response.status !== 200) {
        return {} as Array<IClassroom>;
      }
      return response.data as Array<IClassroom>;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as Array<IClassroom>;
    }
  },
  getInformationClassroom: async (classroom_id: string | undefined) => {
    try {
      const data = {
        uuid_classroom: classroom_id,
      };
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/operation/verify/information/exist/checkin`,
        data,
      );
      return response.data as IValidClass;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return {} as IValidClass;
    }
  },
  classSearchSolution: async (params?: URLSearchParams) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/get/search/solution`,
        undefined,
        params,
      );
      return response.data as ISearchSOL;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return {} as ISearchSOL;
    }
  },
  classSearchSolutions: async (params?: URLSearchParams) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/get/integra/search/solutions`,
        undefined,
        params,
      );
      return response.data as ISearchSOL[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as ISearchSOL[];
    }
  },
  searchClassroomsFromIntegraAPI: async (params?: URLSearchParams) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/get/integra/search/date`,
        undefined,
        params,
      );
      return response.data as IClassroom[];
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return [] as IClassroom[];
    }
  },
  searchClassroomsCalendar: async (params?: URLSearchParams) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/list/calendar`,
        undefined,
        params,
      );
      const eventData = response.data.map((event: EventCalendar) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      })) as EventCalendar[];

      return eventData;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return [] as EventCalendar[];
    }
  },
  searchClassroomsBuildInIntegra: async (
    next?: string,
    previous?: string,
    params?: URLSearchParams,
  ) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let url: string = '';

      if (next) {
        url = next;
      } else if (previous) {
        url = previous;
      } else {
        url = `${BaseURL}/api/v1/classroom/list/build/sas`;
      }

      let response = await get(url, undefined, params);
      return response.data as IClassroomPagination;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return {} as IClassroomPagination;
    }
  },
  getByIDClassroomIntegraTemp: async (classroom_id: string | undefined) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/get/integra/temp/by/${classroom_id}`,
      );
      return response.data as IClassroom;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return {} as IClassroom;
    }
  },
});
