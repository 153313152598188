export default function base64toFile(
  base64: string,
  fileName: string,
  download: boolean = false, // Torna o download opcional
): File | null {
  const regex = /^data:(.+);base64,(.+)/;
  const match = base64.match(regex);

  if (!match || match.length !== 3) {
    console.error('String base64 inválida.');
    return null;
  }

  const mimeType = match[1];
  const base64Data = match[2];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  const file = new File([blob], fileName, { type: mimeType });

  if (download) {
    // Criar URL temporário para download
    const blobUrl = URL.createObjectURL(blob);

    // Criar um link temporário
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;

    // Simular clique para fazer o download
    link.click();

    // Liberar memória
    URL.revokeObjectURL(blobUrl);
  }

  return file;
}
