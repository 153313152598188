type DTO<T = unknown> = Record<string, T>;

class ErrorAPI {
  private _statusCode: number;
  private _message: string;
  private _extras?: DTO;

  constructor(statusCode: number, message: string, extras?: DTO) {
    this._statusCode = statusCode;
    this._message = message;
    this._extras = extras;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  get message(): string {
    return this._message;
  }

  get extras(): DTO | undefined {
    return this._extras;
  }
}

export default ErrorAPI;
